@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

// Corresponds to the go-do value for the style prop.
.goDo {
    background-color: #000;
    padding: 64px 0;
    margin: 0;

    .title {
        max-width: 100ch;
        font-size: 70px;
        font-weight: 50;
        font-weight: 100;
        margin-top: 0;
        background: linear-gradient(105deg, #D5D7BC, #D5D7BC, #7EA8AF, #7EA8AF);
        background-clip: text;
        color: transparent;
    }
}

// Corresponds to the image-tile value for the style prop.
.imageTile {
    margin: 0;

    .textWrapper {
        position: absolute;
        top: 0;
        left: 0;
        margin: 32px;

        :is(h1, h2, h3, h4, h5, h6, p, button) {
          margin: 0;
       }
    }

    .title {
        color: #676767;

        :is(h1, h2, h3, h4, h5, h6, p, button) {
            margin: 0;
         }
    }

    .text {
        :is(h1, h2, h3, h4, h5, h6, p, button) {
            margin: 0;
         }
    }

    .link {
        margin-top: 0;
        color: #676767;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
    }
}

.imageTileTextBottom {
    margin: 0;

    .textWrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 $space-xxs;

        :is(h1, h2, h3, h4, h5, h6, p, button) {
            margin: 0;
        }
    }

    .title {
        color: $color-white;
        margin-bottom: $space-xxs;

        :is(h1, h2, h3, h4, h5, h6, p, button) {
            margin: 0;
         }
    }

    .text {
        :is(h1, h2, h3, h4, h5, h6, p, button) {
            margin: 0;
         }
    }
}

// Corresponds to the image-tile-tall value for the style prop.
.imageTileTall {
    margin: 0;

    .textWrapper {
        position: absolute;
        top: 0;
        left: 0;

        :is(h1, h2, h3, h4, h5, h6, p, button) {
          margin: 0;
       }
    }

    .title {
        color: $color-white;
        margin-bottom: $space-xxs;
    }

    .text {
        margin-bottom: $space-xxs;
    }

    .link {
        color: $color-white;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
    }
}
